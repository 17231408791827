@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --black: #000000;
  --white: #ffffff;
  --white-transparent: rgba(255, 255, 255, 0.4);
  --black-transparent: rgba(0, 0, 0, 0.4);
  --red: #ff5050;
  --purple: #8c1ee6;
  --shadow: rgba(0, 0, 0, 0.1);
  --header-shadow: rgba(0, 0, 0, 0.08);
  --mask: rgba(0, 0, 0, 0.4);
  --toast-success-shadow: rgba(0, 230, 155, 0.5);
  --toast-error-shadow: rgba(255, 80, 80, 0.5);
  --toast-info-shadow: rgba(22, 126, 251, 0.5);
  --toast-warning-shadow: rgba(246, 135, 32, 0.5);
  --gray-50: #f8f8f8;
  --gray-100: #f2f2f2;
  --gray-200: #dedede;
  --gray-300: #d9d9d9;
  --gray-400: #cecece;
  --gray-500: #999;
  --gray-600: #484848;
  --gray-700: #333;
  --gray-800: #222;
  --navy-50: #eff3f5;
  --navy-100: #e5eaee;
  --navy-200: #d1dadf;
  --pink-50: #ff698d;
  --pink-100: #ff4874;
  --pink-200: #de3a61;
  --pink-300: #ff55af;
  --blue-50: #e4f6ff;
  --blue-100: #00b9e6;
  --blue-200: #167efb;
  --blue-300: #0050d2;
  --green-10: #f3f7ed;
  --green-50: #00e69b;
  --green-100: #34c759;
  --green-200: #00b74a;
  --green-300: #21a142;
  --yellow-10: #fffaee;
  --yellow-50: #fff4d2;
  --yellow-100: #ffe070;
  --yellow-200: #ffd814;
  --yellow-300: #ffc814;
  --brown-50: #dfb620;
  --brown-100: #c19e22;
  --orange-50: #ffaf5b;
  --orange-100: #ff9a30;
  --orange-200: #f68720;
  --swiper-pagination-bottom: 0;
}

html,
body {
  margin: 0;
  padding: 0;
  display: block;
}
