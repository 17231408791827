@import '../colors.scss';

.fanme-swiper {
  /** 通常 */
  .swiper-pagination-bullet {
    background: $light-gray-2;
    opacity: 1;
  }

  /** アクティブ */
  .swiper-pagination-bullet-active {
    background: $fanme-yellow;
  }
}

.fanme-swiper-black {
  /** 通常 */
  .swiper-pagination-bullet {
    background: $light-gray-2;
    opacity: 1;
  }

  /** アクティブ */
  .swiper-pagination-bullet-active {
    background: $fanme-black;
  }
}

/** ContentsTutorialで使用 */
.contents-tutorial {
  .swiper-slide {
    height: auto;
  }
  .swiper-pagination {
    top: 240px;
    height: 32px;
  }
}

.menu-swiper {
  .swiper-wrapper {
    @media screen and (min-width: 431px) {
      display: flex !important;
      justify-content: start;
    }
  }
}
